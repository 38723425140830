import React from "react";
import PropTypes from "prop-types";

const AboutUs1 = ({ lang }) => {
  const content = {
    cn: {
      welcomeTitle: "公司介绍",
      tab1Title: "关于我们",
      tab2Title: "为什么选择我们",
      tab1Content: "我们在集成电路领域拥有二十多年的经验。目前，我们拥有大量热门电子元件库存，广泛应用于民用、工业、军事和其他各个领域。我们提供来自包括INFINEON、ADI、AVAGO、INTEL、NXP、TI、MICRON、ON等大多数主流品牌的超过1.5亿件产品。我们与众多知名品牌建立了可靠的合作关系。借助丰富的集成电路经验和与知名品牌的牢固合作，我们能够提供高质量和可靠的产品和服务，满足各行业客户的需求。我们的产品因其在民用、工业、军事和其他领域的广泛应用而备受赞誉。通过与知名品牌的合作，我们已在市场上建立了强大的品牌美誉度和竞争力。",
      tab2Content: "作为电子元器件的分销商，我们在这个行业已有20多年的经验，积累了丰富的专业知识和行业洞察。我们的团队拥有深厚的行业知识和专业技能，能够为客户提供全面的支持和咨询服务。我们公司提供的所有电子元器件均为原装正品，品质可靠。我们严格筛选供应商，确保产品符合最高的质量标准，并通过先进的库存管理系统，快速响应市场需求。我们的物流合作伙伴确保产品能够安全、准时地送达客户手中。此外，我们还提供个性化的客户服务，致力于满足客户的特殊需求。选择我们，意味着选择了可靠性、质量和卓越的服务。"
    },
    en: {
      welcomeTitle: "COMPANY INTRODUCTION",
      tab1Title: "About Us",
      tab2Title: "Why Choose Us",
      tab1Content: "We have over twenty years of experience in the field of integrated circuits. Currently, we have a large inventory of popular electronic components that are widely used in civilian, industrial, military, and other sectors. We offer over 150 million products from most mainstream brands including INFINEON, ADI, AVAGO, INTEL, NXP, TI, MICRON, ON, and others. We have established reliable partnerships with numerous well-known brands. Leveraging our rich experience in integrated circuits and strong collaborations with reputable brands, we are able to provide high-quality and reliable products and services to meet the needs of customers across various industries. Our products are highly acclaimed for their extensive applications in civilian, industrial, military, and other fields. Through collaborations with renowned brands, we have built a strong brand reputation and competitiveness in the market.",
      tab2Content: "As a distributor of electronic components, we have over 20 years of experience in the industry, accumulating rich expertise and insights. Our team possesses profound industry knowledge and professional skills to provide comprehensive support and consulting services to our customers. All electronic components provided by our company are original authentic products, ensuring reliable quality. We rigorously select suppliers to meet the highest quality standards and employ advanced inventory management systems to swiftly respond to market demands. Our logistics partners ensure safe and timely delivery of products to customers. Additionally, we offer personalized customer service to meet specific client needs. Choosing us means choosing reliability, quality, and excellence."
    }
  };

  const currentContent = lang.toLowerCase() === "cn" ? content.cn : content.en;

  return (
    <div className="container-fluid p-5">
      <div className="row gx-5">
        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: "500px" }}>
          <div className="position-relative h-100">
            <img className="position-absolute w-100 h-100 rounded" src="/static/img/main/about.jpg" style={{ objectFit: "cover" }} alt="About Us" />
          </div>
        </div>
        <div className="col-lg-7">
          <div className="mb-4">
            <h1 className="display-3 text-uppercase mb-0">{currentContent.welcomeTitle}</h1>
          </div>
          <div className="rounded bg-dark p-5">
            <ul className="nav nav-pills justify-content-between mb-3">
              <li className="nav-item w-50">
                <a className="nav-link text-uppercase text-center w-100 active" data-bs-toggle="pill" href="#pills-1">
                  {currentContent.tab1Title}
                </a>
              </li>
              <li className="nav-item w-50">
                <a className="nav-link text-uppercase text-center w-100" data-bs-toggle="pill" href="#pills-2">
                  {currentContent.tab2Title}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="pills-1">
                <p className="text-secondary mb-0 text-light">{currentContent.tab1Content}</p>
              </div>
              <div className="tab-pane fade" id="pills-2">
                <p className="text-secondary mb-0 text-light">{currentContent.tab2Content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutUs1.propTypes = {
  lang: PropTypes.string.isRequired
};

export default AboutUs1;