import React from "react";
import PropTypes from "prop-types";

const FactsSection = ({ lang }) => {
  const content = {
    cn: {
      inventory: "库存",
      ourTrainers: "品牌",
      completeProject: "员工",
      happyClients: "客户满意度",
    },
    en: {
      inventory: "Inventory",
      ourTrainers: "Brand",
      completeProject: "Staff",
      happyClients: "Customer satisfaction",
    }
  };

  const currentContent = lang.toLowerCase() === "cn" ? content.cn : content.en;

  return (
    <div className="container-fluid bg-dark facts p-5 my-5">
      <div className="row gx-5 gy-4 py-5">
        <div className="col-lg-3 col-md-6">
          <div className="d-flex">
            <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
              <i className="fa fa-star fs-4 text-white"></i>
            </div>
            <div className="ps-4">
              <h5 className="text-secondary text-uppercase">{currentContent.inventory}</h5>
              <h1 className="display-5 text-white mb-0">
              <span data-toggle="counter-up">999</span><span>+</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="d-flex">
            <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
              <i className="fa fa-star fs-4 text-white"></i>
            </div>
            <div className="ps-4">
              <h5 className="text-secondary text-uppercase">{currentContent.ourTrainers}</h5>
              <h1 className="display-5 text-white mb-0">
              <span data-toggle="counter-up">168</span><span>+</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="d-flex">
            <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
              <i className="fa fa-users fs-4 text-white"></i>
            </div>
            <div className="ps-4">
              <h5 className="text-secondary text-uppercase">{currentContent.completeProject}</h5>
              <h1 className="display-5 text-white mb-0">
              <span data-toggle="counter-up">86</span><span>+</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="d-flex">
            <div className="bg-primary rounded-circle d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
              <i className="fa fa-star fs-4 text-white"></i>
            </div>
            <div className="ps-4">
              <h5 className="text-secondary text-uppercase">{currentContent.happyClients}</h5>
              <h1 className="display-5 text-white mb-0">
              <span data-toggle="counter-up">100</span><span>%</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FactsSection.propTypes = {
  lang: PropTypes.string.isRequired
};

export default FactsSection;
