import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-bootstrap/Carousel";

const CompanyInfoWithTestimonials = ({ lang }) => {
  const content = {
    cn: {
      welcomeTitle: "客户评价",
      testimonials: [
        {
          title: "高效采购，品质保障",
          content: "作为一家电子制造企业，我们一直在寻找可靠的电子元器件供应商。自从与这家公司合作以来，我们的采购流程变得更加高效，产品质量也得到了保证。他们提供的原装正品电子元器件不仅品质优良，而且交货速度非常快。",
          avatar: "/static/img/cus.jpg",
          name: "李先生，电子制造公司采购经理",
          date: "2010-01-20"
        },
        {
          title: "及时交货，优质服务",
          content: "我们是一家初创公司，对电子元器件的需求量虽然不大，但对质量要求非常高。这家公司不仅能够按时交货，而且他们的售后服务也非常到位，能够及时解决我们遇到的问题。非常感谢他们的支持！",
          avatar: "/static/img/cus.jpg",
          name: "刘小姐，初创公司负责人",
          date: "2012-04-27"
        },
        {
          title: "价格合理，品质出众",
          content: "在市场上找到价格合理且品质出众的电子元器件供应商并不容易，但这家公司做到了。我们一直从他们那里采购各种电子元器件，每一次的合作都非常愉快。他们的产品质量稳定，价格也很有竞争力。",
          avatar: "/static/img/cus.jpg",
          name: "陈先生，电子设备制造商",
          date: "2015-08-28"
        }
      ]
    },
    en: {
      welcomeTitle: "Customer Testimonials",
      testimonials: [
        {
          title: "Excellent Service, Trusted Choice",
          content: "Our company has been working with this electronic components distributor for many years. All the components they provide are original and authentic, with very reliable quality. Whenever we encounter technical issues or need consultation, their team always responds quickly and offers professional advice, which gives us great peace of mind. We are very satisfied with both the product quality and the after-sales service.",
          avatar: "/static/img/Jennifer.jpg",
          name: "Jennifer",
          date: "2008-06-20"
        },
        {
          title: "Efficient Procurement, Quality Assurance",
          content: "As an electronics manufacturing company, we have always sought a reliable supplier for electronic components. Since partnering with this company, our procurement process has become more efficient, and the product quality is guaranteed. The original authentic components they provide are of excellent quality, and their delivery speed is very fast. Highly recommended!",
          avatar: "/static/img/John.jpg",
          name: "John",
          date: "2015-09-15"
        },
        {
          title: "Reliable Supplier, Trustworthy Service",
          content: "When selecting an electronic component supplier, reliability and integrity are our top priorities.  This company excels in both areas.  Every component we purchase from them is original and authentic, and the quality is consistently reliable.  We are very satisfied with their service.",
          avatar: "/static/img/Nina.jpg",
          name: "Nina",
          date: "2022-06-18"
        }
      ]
    }
  };

  const currentContent = lang.toLowerCase() === "cn" ? content.cn : content.en;

  return (
    <div className="container-fluid p-5">
      <div className="row gx-5">
        <div className="col-lg-7">
          <div className="mb-4">
            <h1 className="display-3 text-uppercase mb-0">{currentContent.welcomeTitle}</h1>
          </div>
          <div className="rounded bg-dark p-5">
            <Carousel id="testimonialCarousel" indicators={false} controls={false} interval={3000}>
              {currentContent.testimonials.map((testimonial, index) => (
                <Carousel.Item key={index}>
                  <div className="d-flex align-items-start justify-content-start flex-row">
                    <img
                      className="rounded-circle me-3"
                      src={testimonial.avatar}
                      alt={testimonial.name}
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                    <div className="d-flex flex-column">
                      <h4 className="text-light">{testimonial.name}</h4>
                      <p className="text-secondary mb-0 text-light">{testimonial.date}</p>
                      <h5 className="text-light">{testimonial.title}</h5>
                      <p className="text-secondary mb-0 text-light">{testimonial.content}</p>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="col-lg-5" style={{ minHeight: "200px" }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded"
              src="/static/img/main/white.jpg"
              style={{ objectFit: "cover" }}
              alt="About Us"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyInfoWithTestimonials.propTypes = {
  lang: PropTypes.string.isRequired
};

export default CompanyInfoWithTestimonials;
