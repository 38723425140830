import { useEffect, useState } from "react";
import axios from "../../../_axios";

const cnInfo = [
  "常见问题解答(如有更多问题，请联系我们)",
  "顾客",
  "为什么选择佳创伟业？",
  "客服",
  "佳创伟业科技有限公司专注于集成电路领域已有20多年，是一家经验丰富的电子元器件代理商和渠道分销商。我们的团队拥有深厚的行业知识和专业技能，能够为客户提供全面的支持和咨询服务。我们公司提供的所有电子元器件均为原装正品，品质可靠。我们与多家知名厂商建立了稳固的合作关系，确保所供产品的质量和可靠性。",
  "顾客",
  "公司经营的主要产品有哪些？客户群体是哪些行业？",
  "客服",
  "我们公司主要经营电子元器件，包括但不限于电阻、电容、二极管、晶体管、集成电路（IC）、传感器、连接器等。我们提供来自多个知名品牌的产品，并且拥有丰富的型号和规格，您可以在'产品'页面中查看。我们的客户涵盖电子、通信、汽车、工业控制等多个行业。我们与众多客户建立了长期稳定的合作关系，并取得了良好的业绩。",
  "顾客",
  "能提供'产品'页面里没有列出的其他电子元件或产品吗？",
  "客服",
  "当然，如果您需要，我们可以为您寻找任何产品。点击“发送询价”按钮，向我们发送询价信息，包括零件号和您需要的数量。",
  "顾客",
  "发货以及交货时间多长？是否有最低订单量要求并提供国际货运服务？",
  "客服",
  "我们保持库存充足，并提供快速的交货服务。具体交货时间取决于订单的大小和产品的供应情况。我们的现货产品，可随时发货。我们不设定最低订单量要求，以满足客户不同需求。我们提供国际货运服务，并且熟悉各种国际进出口流程和法规，可以为客户提供专业的物流解决方案和支持。",
  "顾客",
  "是否提供质量保证或保修？",
  "客服",
  "是的，我们提供产品质量的保证，并且有保修期。如果您对我们的产品有任何疑问，请联系我们的客户服务团队，我们将尽快回复你的问题。",
  "顾客",
  "产品的价格如何？是否提供产品的技术文档和规格说明？",
  "客服",
  "我们的产品价格根据型号、数量和市场变化而变化，我们提供竞争性的价格，并且根据订单量提供相应的折扣。我们可以提供产品的技术文档、规格说明以及相关资料供你参考。",
  "温馨提示",
  "如果您还有不明白的，欢迎向我们提问！",
  "姓名",
  "电话",
  "邮箱/微信/QQ",
  "问题",
  "提交",
];

const enInfo = [
  "FAQs (If you have more questions, please contact us)",
  "Customer",
  "Why choose JCWYIC?",
  "Customer Service",
  "JiaChuang WeiYe Technology Co., Ltd. has been focusing on the field of integrated circuits for over 20 years, and is an experienced electronic component distributor and channel distributor. Our team has deep industry knowledge and professional skills, able to provide comprehensive support and consulting services to customers. All electronic components provided by our company are original authentic products with reliable quality. We have established solid partnerships with many well-known manufacturers to ensure the quality and reliability of the products we supply.",
  "Customer",
  "What are the main products operated by the company? What industries are the customer base from?",
  "Customer Service",
  "Our company mainly deals in electronic components, including but not limited to resistors, capacitors, diodes, transistors, integrated circuits (ICs), sensors, connectors, etc. We provide products from multiple well-known brands and have a rich variety of models and specifications, which you can view on the 'Products' page. Our customers come from multiple industries including electronics, communications, automotive, industrial control, and more. We have established long-term stable cooperation relationships with numerous customers and have achieved good performance.",
  "Customer",
  "Can you provide other electronic components or products not listed on the 'Products' page?",
  "Customer Service",
  "Of course, if you need, we can find any product for you. Click the 'Send Inquiry' button to send us an inquiry message, including the part number and the quantity you need.",
  "Customer",
  "How long is the delivery and lead time? Is there a minimum order quantity requirement and do you provide international shipping services?",
  "Customer Service",
  "We keep ample stock and provide fast delivery services. The specific delivery time depends on the size of the order and the supply of the product. Our in-stock products can be shipped anytime. We do not set a minimum order quantity requirement to meet the different needs of customers. We provide international shipping services and are familiar with various international import and export procedures and regulations, able to provide professional logistics solutions and support to customers.",
  "Customer",
  "Do you provide quality assurance or warranty?",
  "Customer Service",
  "Yes, we provide assurance of product quality and offer a warranty period. If you have any questions about our products, please contact our customer service team, and we will respond to your inquiries as soon as possible.",
  "Customer",
  "What are the prices of the products? Do you provide technical documents and specifications for the products?",
  "Customer Service",
  "Our product prices vary depending on the model, quantity, and market fluctuations. We offer competitive prices and provide corresponding discounts based on the order quantity. We can provide technical documents, specifications, and related materials for your reference.",
  "Warm Prompt",
  "If you have other questions to learn about , you can contact us at any time.",
  "Name",
  "Skype/WhatsApp",
  "Email",
  "Problem",
  "Submit",
];

const initQuestionInfo = {
  name: "",
  email: "",
  phone: "",
  question: "",
};

const DetailStart = (props) => {
  const { lang } = props;
  const [langInfo, setLangInfo] = useState(cnInfo);
  const [questionInfo, setQuestionInfo] = useState(initQuestionInfo);

  useEffect(() => {
    setLangInfo(lang === "cn" ? cnInfo : enInfo);
  }, [lang]);

  const submitQuestionData = (questionInfo) => {
    let isKongKeyValue = false;
    Object.keys(questionInfo).forEach((key) => {
      if (!questionInfo[key]) {
        isKongKeyValue = true;
      }
    });
    if (isKongKeyValue) {
      alert(
        lang === "cn"
          ? "请将问题信息输入完整!"
          : "Please enter the question information completely!"
      );
    } else {
      axios.post("/back-server/api/question", questionInfo).then((data) => {
        console.log("data", data);
        alert(
          lang === "cn"
            ? "您的问题已经提交，稍后有人会与你联系!"
            : "Your question has been submitted and we will get back to you as soon as possible."
        );
      });
    }
  };

  return (
    <div className="container-fluid p-5">
      <div className="row g-5">
        <div className="col-lg-8">
          <div className="mb-5">
            <h3 className=" mb-5">{langInfo[0]}</h3>
            <div className="d-flex mb-3">
              <img
                src="/static/img/customer.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[1]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[2]}</p>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img
                src="/static/img/user.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[3]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[4]}</p>
              </div>
            </div>
            <div className="d-flex mb-2">
              <img
                src="/static/img/customer.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[5]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[6]}</p>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img
                src="/static/img/user.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
                
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[7]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[8]}</p>
              </div>
            </div>
            <div className="d-flex mb-2">
              <img
                src="/static/img/customer.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[9]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[10]}</p>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img
                src="/static/img/user.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[11]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[12]}</p>
              </div>
            </div>  
            <div className="d-flex mb-2">
              <img
                src="/static/img/customer.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[13]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[14]}</p>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img
                src="/static/img/user.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[15]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[16]}</p>
              </div>
            </div>
            <div className="d-flex mb-2">
              <img
                src="/static/img/customer.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[17]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[18]}</p>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img
                src="/static/img/user.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[19]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[20]}</p>
              </div>
            </div>
                        <div className="d-flex mb-2">
              <img
                src="/static/img/customer.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[21]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[22]}</p>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img
                src="/static/img/user.jpg"
                className="img-fluid rounded"
                style={{ width: 45, height: 45 }}
                alt=""
              />
              <div className="ps-3">
                <h6>
                  <a href="">{langInfo[23]}</a>{" "}
                  <small>
                    <i></i>
                  </small>
                </h6>
                <p>{langInfo[24]}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="mb-5">
            <h3 className="mb-5">{langInfo[25]}</h3>
            <div
              className="bg-dark rounded text-center text-light"
              style={{ padding: 30 }}
            >
              {langInfo[26]}
            </div>
          </div>
          <div className="bg-dark rounded p-4">
            <h3 className="text-light text-uppercase mb-4"></h3>
            <form>
              <div className="row g-3">
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control bg-white border-0"
                    placeholder={langInfo[27]}
                    style={{ height: 55 }}
                    defaultValue={questionInfo.name}
                    onChange={(e) => {
                      setQuestionInfo((info) => {
                        let newInfo = Object.assign(info);
                        newInfo.name = e.target.value;
                        return newInfo;
                      });
                    }}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <input
                    type="phone"
                    className="form-control bg-white border-0"
                    placeholder={langInfo[28]}
                    style={{ height: 55 }}
                    defaultValue={questionInfo.phone}
                    onChange={(e) => {
                      setQuestionInfo((info) => {
                        let newInfo = Object.assign(info);
                        newInfo.phone = e.target.value;
                        return newInfo;
                      });
                    }}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control bg-white border-0"
                    placeholder={langInfo[29]}
                    style={{ height: 55 }}
                    defaultValue={questionInfo.email}
                    onChange={(e) => {
                      setQuestionInfo((info) => {
                        let newInfo = Object.assign(info);
                        newInfo.email = e.target.value;
                        return newInfo;
                      });
                    }}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control bg-white border-0"
                    rows="5"
                    placeholder={langInfo[30]}
                    defaultValue={questionInfo.question}
                    onChange={(e) => {
                      setQuestionInfo((info) => {
                        let newInfo = Object.assign(info);
                        newInfo.question = e.target.value;
                        return newInfo;
                      });
                    }}
                  ></textarea>
                </div>
                <div className="col-12">
                  <a
                    className="btn btn-primary w-100 py-3"
                    onClick={() => submitQuestionData(questionInfo)}
                  >
                    {langInfo[31]}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailStart;
