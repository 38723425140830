import { useEffect, useState } from "react";

const cnInfo = [
  "公司信息",
  "深圳市佳创伟业科技有限公司",
  "深圳市福田区振华路飞亚达大厦西座",
  "联系电话",
  "陈先生",
];

const enInfo = [
  "Company Information",
  "ShenZhen JiaChuang WeiYe Technology Co.,Ltd",
  "West Building, Feiyada Building, Zhenhua Road, Futian District, Shenzhen",
  "Telephone",
  "Alex",
];

const ContactStart = (props) => {
  const { lang } = props;
  const [langInfo, setLangInfo] = useState(cnInfo);

  useEffect(() => {
    setLangInfo(lang === "cn" ? cnInfo : enInfo);
  }, [lang]);
  return (
    <div className="container-fluid p-5">
      <div className="row g-5 mb-5">
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">{langInfo[0]}</h5>
            <p className="text-secondary mb-2">{langInfo[1]}</p>
            <p className="text-secondary mb-0">{langInfo[2]}</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">{langInfo[3]}</h5>
            <p className="text-secondary mb-2">{langInfo[4]}</p>
            <p className="text-secondary mb-0">
              +86 13342986657
            </p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">QQ/Wechat/Email</h5>
            <p className="text-secondary mb-2">QQ: 3117541604/ Wechat: Hathcole</p>
            <p className="text-secondary mb-0">Email:vivi@jcwyic.com</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex flex-column align-items-center bg-dark rounded text-center py-5 px-3">
            <h5 className="text-uppercase text-primary mb-4">Whatsapp/Skype</h5>
            <p className="text-secondary mb-2">Whatsapp: +86 13342986657</p>
            <p className="text-secondary mb-0">Skype: vivi@jcwyic.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactStart;
