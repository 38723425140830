import { useEffect, useState } from "react";
import axios from "../../../_axios";

const cnInfo = [
  "",
  "原装正品",
  "品质第一",
  "快速响应",
  "可靠供应",
  "技术支持",
  "合作共赢",
  "型号",
  "品牌",
  "封装",
  "数量",
  "询价",
  "年份",
];

const enInfo = [
  "",
  "Original Authentic",
  "Quality First",
  "Prompt Response",
  "Reliable Supply",
  "Technical Support",
  "Win-Win Cooperation",
  "Part No",
  "Brand",
  "Package",
  "Qty",
  "Request For Quotation",
  "D/C",
];

const initPageInfo = {
  pageSize: 5,
  currentPage: 1,
  model: "",
};

const initPageData = {
  data: [],
  totalCount: 0,
  totalPage: 0,
};

const Blog1 = (props) => {
  const { lang } = props;
  const [langInfo, setLangInfo] = useState(cnInfo);
  const [pageInfo, setPageInfo] = useState(initPageInfo);
  const [pageData, setPageData] = useState(initPageData);

  useEffect(() => {
    setLangInfo(lang === "cn" ? cnInfo : enInfo);
  }, [lang]);

  useEffect(() => {
    searchPageData(pageInfo);
  }, []);

  const searchPageData = (pageInfo) => {
    handlePageData(pageInfo);
  };

  const selectPageData = (pageInfo) => {
    setPageInfo(pageInfo);
    handlePageData(pageInfo);
  };

  const handlePageData = (pageInfo) => {
    axios.post("/back-server/api/inventory", pageInfo).then((data) => {
      if (data.value && Array.isArray(data.value.data)) {
        setPageData(data.value);
      } else if (
        data.value &&
        Object.prototype.toString.call(data.value.data) === "[object Object]"
      ) {
        let pageData = {
          data: [data.value.data],
          totalCount: data.value.totalCount,
          totalPage: data.value.totalPage,
        };
        setPageData(pageData);
      } else {
        setPageData(data.value);
      }
    });
  };

  return (
    <div className="container-fluid p-5">
      <div className="row g-5">
        <div className="col-lg-4">
          <div className="mb-5">
            <h3 className="text-uppercase mb-4">{langInfo[0]}</h3>
            <div className="bg-dark rounded p-4">
              {langInfo.slice(1, 7).map((info, index) => (
                <div key={index} className="d-flex overflow-hidden mb-3 bg-light justify-content-center">
                  <a
                    target="_blank"
                    style={{ marginLeft: 50 }}
                    className="d-flex align-items-center bg-light rounded-end h5 text-uppercase p-3 mb-0"
                  >
                    {info}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="row g-5 pt-4">
            {Array.isArray(pageData.data) && pageData.data.length > 0 ? (
              <>
                {pageData.data.map((item, index) => (
                  <div key={index} className="job-item p-4 mt-0 mb-0">
                    <div className="row g-4">
                      <div className="col-sm-12 col-md-8 d-flex align-items-center">
                        <img
                          className="flex-shrink-0 img-fluid border rounded"
                          src={
                            item.photo
                              ? `https://www.jcwyic.com/back-server/sys/common/static/${item.photo}`
                              : "/static/img/img/com-logo-1.jpg"
                          }
                          alt=""
                          style={{ width: 80, height: 80 }}
                        />
                        <div className="text-start ps-4">
                          <h5 className="mb-3">{`${langInfo[7]}: ${item.model}`}</h5>
                          <span className="text-truncate me-3">{`${langInfo[8]}: ${item.brand}`}</span>
                          <span className="text-truncate me-3">{`${langInfo[9]}: ${item.fengzhuang}`}</span>
                          <span className="text-truncate me-0">{`${langInfo[10]}: ${item.quantity}`}</span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                        <div className="d-flex mb-3">
                          <a
                            className="btn btn-primary"
                            href={`RFQ`}
                            target="_blank"
                          >
                            {`${langInfo[11]}`}
                          </a>
                        </div>
                        <small className="text-truncate">
                          <i className="far fa-calendar-alt text-primary me-2"></i>
                          {`${langInfo[12]}: ${item.year}`}
                        </small>
                      </div>
                    </div>
                  </div>
                ))}
<div className="col-8 d-flex align-items-center justify-content-end">
  {lang === "cn" ? (
    <div className="text-black fw-bold text-uppercase" style={{ textAlign: "center", fontSize: 16 }}>
      {`共有库存信息${pageData.totalCount}条 每页5条 第${pageInfo.currentPage}页/共${pageData.totalPage}页`}
    </div>
  ) : (
    <div className="text-black fw-bold text-uppercase" style={{ textAlign: "center", fontSize: 16 }}>
      {`Total information ${pageData.totalCount}  , Page No: ${pageInfo.currentPage} / ${pageData.totalPage} , 5 items /page`}
    </div>
  )}
<button className="btn btn-primary ms-3" style={{ width: '200px' }} onClick={() => window.location.href="/PRODUCTS"}>  
  {lang === "cn" ? "更多产品" : "More Part Number"}
  </button>
</div>
              </>
            ) : (
              <div
                className="display-6 text-black mb-md-4"
                style={{ textAlign: "center" }}
              >
                {`We don't have the "${pageInfo.model}" model in stock.
                  Please send us an RFQ and we will provide you with a quotation.`}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog1;
